import React, {  useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { Link, useNavigate  } from "react-router-dom";
import axios from "axios";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
export default function Signin() {
  const [formData  , setFormData ] = useState({
      email  : "",
      password:"",
  });
  const navigate = useNavigate();
  const [submittedData, setSubmitData] = useState(null);
  const [signInText , setSignInText] = useState('Sign In');
  const [isDisabled , setBtnDisabled] = useState('');
  const setData = (e) =>{
      const {name  , value }   = e.target ;
      setFormData({
        ...formData,
        [name]:value,
        });
  }
  
  const  submitLogin = async (e) => {
      e.preventDefault();
      setSubmitData(formData);
      let apiURL  = "https://salonistapp.com/api/login";
      setSignInText('Please wait..');
      setBtnDisabled(true)
        try{
            
          const response  =  (await axios.post(apiURL, formData));
          let responseData =  response.data;
          if(responseData.success === 200){
            setBtnDisabled(true)
            setSignInText("Logged In");
            toastr.success(responseData.message);
            sessionStorage.setItem('domainId' ,  responseData.data.domainId ?? "");
            navigate('/')
          }

        }
        catch(e){
            setSignInText('Sign in');
            setBtnDisabled(false)
            toastr['error'](e.response.data.message);
            return false;
        }
      
  }

  return (
   
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <Link to="/" className="header-logo mb-4">dashbyte</Link>
          <Card.Title>Sign In</Card.Title>
          <Card.Text>Welcome back! Please signin to continue.</Card.Text>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={submitLogin}>
            <div className="mb-4">
              <Form.Label >Email address</Form.Label>
              <Form.Control type="email" name='email' placeholder="Enter your email address" value={formData.email} onChange={setData}/>
            </div>
            <div className="mb-4">
              <Form.Label className="d-flex justify-content-between">Password </Form.Label>
              <Form.Control type="password" name='password' placeholder="Enter your password" value={formData.password} onChange={setData} />
            </div>
            <div className="mb-4">
              <Form.Label className="d-flex justify-content-between">Shop </Form.Label>
              <Form.Control type="text" name='shop' placeholder="" value={formData.shop} onChange={setData} readonly/>
            </div>
            <Button type="submit"  disabled={isDisabled} variant="primary" className="btn-sign " >{signInText}</Button>

          

          </Form>
              
              
        </Card.Body>
        <Card.Footer>
          
        </Card.Footer>
      </Card>
    </div>
  )
}