import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';

function PrivateRoute(){
    const domainId = sessionStorage.getItem('domainId');

    if(!domainId){
        return <Navigate to='/signin'/>
    }
    return <Outlet />
}

export default  PrivateRoute ;