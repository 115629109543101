import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Card, Container, Nav, Table,Tooltip, OverlayTrigger } from "react-bootstrap";
import Footer from "../layouts/Footer";
import Sidebar from "../layouts/Sidebar";
import { Link } from "react-router-dom";
import Header from "../layouts/Header"; 
import { Grid } from "gridjs-react";
import axios from "axios";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
// or use moment if you prefer

export default function Products() {
    const currentSkin = (localStorage.getItem('skin-mode'))? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);
    const [data, setData] = useState([]);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [fetchText, setFetchText] = useState('Fetch Products');
    
    const fetchProducts = async () => {
      setBtnDisabled(true);
      setFetchText('Fetching Products');
      try {
        const fetchDataURL = 'https://salonistapp.com/api/services';
        let response = await axios.post(fetchDataURL, {
          domainId: sessionStorage.getItem('domainId'),
        });
    
        let responseData = response.data;
        if (responseData.success === 200) {
          setBtnDisabled(false);
          setFetchText('Fetch Products');
          toastr.success(responseData.message);
          // Fetch all products after the successful response
          allService();
        }
      } catch (error) {
        setBtnDisabled(false);
        setFetchText('Fetch Products');
        toastr.error(error.response?.data?.message || 'Error fetching products');
      }
    };
    
    const allService = async () => {
      try {
        const allProductsURL = 'https://salonistapp.com/api/services';
        const response = await axios.get(allProductsURL, {
          params: {
            domain_id: sessionStorage.getItem('domainId'),
          },
        });
    
        if (response.data.success === 200) {
          setData(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching all services:', error);
      }
    };
    
    // You can optionally call fetchProducts or allService on component mount
    useEffect(() => {
      allService();
    }, []);
    return (
        <React.Fragment>
          <Sidebar/>
          <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Products / All</li>
            </ol>
            <h4 className="main-title mb-0">Products</h4>
          </div>

        </div>
        
        <div className="main-docs mr-1">   
            <Card >
            <Card.Body>
              <div className="table-responsive">
              <Table className="mb-0 table ">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Brand</th>
                    <th scope="col">Image</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Service Category</th>
                    <th scope="col">Type</th>
                    <th scope="col">Full Price</th>
                    <th scope="col">Cost Price</th>
                    <th scope="col">Sale Price</th>
                
                    
                  </tr>
                </thead>
                <tbody>
                  {data.map((items)=>(
                  <tr>
                  <td scope="col">{items.product_id}</td>
                  <td scope="col">{items.name}</td>
                  <td scope="col">{items.brand}</td>
                  <td scope="col">{items.img}</td>
                  <td scope="col">{items.qty ?? 0}</td>
                  <td scope="col">{items.product_type}</td>
                  <td scope="col">{items.type}</td>
                  <td scope="col">{items.full_price ?? ""}</td>
                  <td scope="col">{items.cost_price}</td>
                  <td scope="col">{items.sale_price}</td>
                  </tr>))
                  }
                 
                </tbody>
              </Table></div>
            </Card.Body>
            </Card>
         
            </div>
            </div>
      <Footer />
    </React.Fragment>
 );
}
