import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Card, Container, Nav, Table,Tooltip, OverlayTrigger } from "react-bootstrap";
import Footer from "../layouts/Footer";
import Sidebar from "../layouts/Sidebar";
import { Link } from "react-router-dom";
import Header from "../layouts/Header"; 
import { Grid } from "gridjs-react";


export default function Packages() {
    const currentSkin = (localStorage.getItem('skin-mode'))? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);

    return (
        <React.Fragment>
          <Sidebar/>
          <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Packages / All</li>
            </ol>
            <h4 className="main-title mb-0">Packages</h4>
          </div>

        </div>
        
        <div className="main main-docs mr-1">   
            <Card >
            <Card.Body>
              <Table className="mb-0">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Job Title</th>
                    <th scope="col">Degree</th>
                    <th scope="col">Salary</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Adrian Monino</td>
                    <td>Front-End Engineer</td>
                    <td>Computer Science</td>
                    <td>$120,000</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Socrates Itumay</td>
                    <td>Software Engineer</td>
                    <td>Computer Engineering</td>
                    <td>$150,000</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Reynante Labares</td>
                    <td>Product Manager</td>
                    <td>Business Management</td>
                    <td>$250,000</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
            </Card>
         
            </div>
            </div>
      <Footer />
    </React.Fragment>
 );
}
